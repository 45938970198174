.variants-basket{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.variants-basket .variant-basket{
  
  padding: 10px;
  width: calc(33% - 20px);
}
.variant-basket input[type=number], .variant-basket input[type=number]:focus, .variant-basket input[type=number]:focus-visible{
  border: none;
  padding: 10px;
  background: none;
  font-size: 16px;
  max-width: 40px;
}

.variant-basket span{
  font-size: 14px;
  margin: 5px;
}

.variants-basket table{
  max-width: 100%;
  border-spacing: 0 !important;
  border-collapse: collapse !important;
  overflow: auto;
}
.variants-basket .variants-header-wrapper{
  width: 75%;
  overflow-x: auto;
}
.variants-basket .variants-header {
  width: 95%;
}
.variants-basket .variants-selected-header{
  width: 100%;
}
.variants-basket table th{
  background: #7e7e7e;
  color: #fff;
  font-weight: 500;
  border: 1px solid #fff;
}
.variants-basket table th,
.variants-basket table td {
  width: 25%;
}
.variants-basket table > tbody > tr:nth-child(odd) > td {
  background-color: #ECECEC;
  border: 1px solid #ddd;
}

.variants-basket table > tbody > tr:nth-child(even) > td {
  background-color: #fff;
}

.variants-basket table > tbody > tr td {
  border: 1px solid #ddd;
  white-space: nowrap;
}
.custom-stepper-btn{
  border-radius: 50%;
  border: 1px solid #707070;
  font-size: 10px;
  font-weight: 600;
  width: 30px;
  height: 30px;
}
.discount-message{
  color: #2f73b1;
  background: #dbfbff;
  padding: 10px;
  max-width: 500px;
  margin: 10px 0;
}
.more-qtys {
  display: none;
  position: absolute;
  bottom: 55%;
  left: -60%;
  flex-direction: column;
  background: #03a9f4;
  border-top: 4px solid #0974c9;
  color: #fff;
  padding: 10px;
  font-size: 17px;
  z-index: 2;
  max-height: 300px;
  overflow: auto;
}
.view-more, .view-more-disabled{
  margin-left: 5px;
}
.view-more{
  cursor: pointer;
}
.view-more-disabled{
  color: #b9b9b9 !important;
}
.view-more:hover + .more-qtys{
  display: flex;
}
.more-qtys:hover{
  display: flex;
}
.selected-variants-wrapper{
  width: 25%;
  position: sticky;
  top: 0;
}
.add-to-basket{
  background: #2f73b1;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  border: none;
  border-radius: 7px;
  transition: all .2s ease;
  cursor: pointer;
}
.add-to-basket:hover{
  -webkit-box-shadow: 0px 0px 5px 2px #2f73b1; 
  box-shadow: 0px 0px 5px 2px #2f73b1;
}
.filterVariants {
  background: #7e7e7e;
}
.filterVariants > div {
  background: #7e7e7e;
  border: none;
}
th[data-order="asc"], th[data-order="desc"]{
  cursor: pointer;
  transition: all .2s ease;
}
th[data-order="asc"]:hover, th[data-order="desc"]:hover{
  background: #a8a8a8;
}
th[data-order="asc"]:after{
  content: "▲"
}
th[data-order="desc"]:after{
  content: "▼"
}
.toast-price-change{
  font-size: 20px !important;
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb;
  border-top: 4px solid #155724 !important;
}
.qty-row{
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width:1175px) {
  table.variants-header {
      display: block;
      margin: auto 0;
  }
  .variants-basket .variants-header-wrapper {
      width: 100%;
  }
  
  .selected-variants-wrapper {
      width: 95%;
      margin-top:15px;
  }
}
  
@media screen and (max-width: 991px) {
  .product-page .extra-info {
      padding: 2px;
  }
}


table.variants-header td, table.variants-header span {
  font-size: 13px;
}
table.variants-header th {
  font-size: 14px;
}