.variants-basket{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.variants-basket .variant-basket{
  
  padding: 10px;
  width: calc(33% - 20px);
}
.variant-basket input[type=number], .variant-basket input[type=number]:focus, .variant-basket input[type=number]:focus-visible{
  border: none;
  padding: 10px;
  background: none;
  font-size: 16px;
  max-width: 75px;
}

.variant-basket span{
  font-size: 14px;
  margin: 5px;
}

.variants-basket table{
  max-width: 100%;
  border-spacing: 0 !important;
  border-collapse: collapse !important;
  overflow: auto;
}
.variants-basket .variants-header{
  width: 70%;
}
.variants-basket .variants-selected-header{
  width: 100%;
}
.variants-basket table th{
  background: #7e7e7e;
  color: #fff;
  font-weight: 500;
  border: 1px solid #fff;
}
.variants-basket table th,
.variants-basket table td {
  width: 25%;
}
.variants-basket table > tbody > tr:nth-child(odd) > td {
  background-color: #ECECEC;
  border: 1px solid #ddd;
}

.variants-basket table > tbody > tr:nth-child(even) > td {
  background-color: #fff;
}
.discount-container .basic-info{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.discount-container .basic-info .image img {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
}
.discount-container .basic-info .image {
  width: 50%;
}
.discount-container .basic-info .details {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 32px;
}


.discount-container .basic-info .details .code {
  font-size: 12px;
}

.discount-container .basic-info .details .name {
  margin-top: 4px;
  font-size: 30px;
}

.discount-container .basic-info .details .price {
  margin-top: 16px;
}

.discount-container .basic-info .details .price .original-price {
  margin-right: 8px;
  text-decoration: line-through;
  font-size: 18px;
}

.discount-container .basic-info .details .price .current-price {
  font-size: 30px;
}

.discount-container .basic-info .details > .controls {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-self: flex-end;
  margin-top: 32px;
}

.discount-container .basic-info .details > .controls > * {
  margin: 0 8px;
}

.discount-container .basic-info .details > .controls .number-picker {
  margin-left: 0;
  font-size: 18px;
}

.discount-container .basic-info .details > .controls .number-picker span {
  font-size: 18px;
}
.discount-container .extra-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 16px;
  max-width: 100%;
}

.discount-container .extra-info .header {
  padding: 8px;
  background-color: #f9f9f9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 18px;
}

.discount-container .extra-info .description {
  padding: 16px;
  background-color: #f9f9f9;
  font-size: 14px;
  max-width: 100%;
}
.discount-container .extra-info .description table{
  max-width: 100%;
  border-spacing: 0 !important;
  border-collapse: collapse !important;
  overflow: auto;
  width: 100%;
  /* max-width: 300px; */
  display: block;
}
.discount-container .extra-info .description table > tbody > tr > td {
  padding: 10px 3px;
}
.discount-container .extra-info .description table > tbody > tr:nth-child(odd) > td {
  background-color: #ECECEC;
  border: 1px solid #ddd;
}
.discount-container .extra-info .description table > tbody > tr td {
  border: 1px solid #ddd;
  white-space: nowrap;
}
.discount-container .extra-info .description table > tbody > tr:nth-child(even) > td {
  background-color: #fff;
}

.variants-basket table > tbody > tr td {
  border: 1px solid #ddd;
  white-space: nowrap;
}
.custom-stepper-btn{
  border-radius: 50%;
  border: 1px solid #707070;
  font-size: 10px;
  font-weight: 600;
  width: 30px;
  height: 30px;
}
.more-qtys{
  display: none;
  position: absolute;
  bottom: 55%;
  left: 0;
  flex-direction: column;
  background: #ff5d5d;
  color: #fff;
  padding: 10px;
  font-size: 17px;
  z-index: 2;
}
.view-more, .view-more-disabled{
  margin-left: 5px;
}
.view-more{
  cursor: pointer;
}
.view-more-disabled{
  color: #b9b9b9 !important;
}
.view-more:hover + .more-qtys{
  display: flex;
}
.selected-variants-wrapper{
  width: 25%;
  position: sticky;
  top: 0;
}
.add-to-basket{
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  border: none;
  border-radius: 7px;
  transition: all .2s ease;
  cursor: pointer;
}
.add-to-basket:hover{
  -webkit-box-shadow: 0px 0px 5px 2px #000000; 
  box-shadow: 0px 0px 5px 2px #000000;
}
.filterVariants {
  background: #7e7e7e;
}
.filterVariants > div {
  background: #7e7e7e;
  border: none;
}
th[data-order="asc"], th[data-order="desc"]{
  cursor: pointer;
  transition: all .2s ease;
}
th[data-order="asc"]:hover, th[data-order="desc"]:hover{
  background: #a8a8a8;
}
th[data-order="asc"]:after{
  content: "▲"
}
th[data-order="desc"]:after{
  content: "▼"
}
.toast-price-change{
  background: #a8ffa8 !important;
  color: #333 !important;
  font-size: 20px !important;
}
.qty-row{
  display: flex;
  justify-content: space-between;
}

.discount-wrapper{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #00000060;
  display: flex;
  z-index: 10;
}
.discount-container{
  width: 80%;
  height: 80%;
  margin: auto;
  background: #FFFFFF;
  position: relative;
}
.discount-container-lg{
  width: 95%;
  height: 90%;
}
.discount-inner{
  overflow: auto;
  height: calc(100% - 100px);
  padding: 50px;
}
.discount-container .close-discount-btn {
  position: absolute;
  font-size: 25px;
  cursor: pointer;
  transition: all .2s ease;
  color: #2f73b1;
  border: 1px solid #2f73b1;
  background: #dafafe;
  border-radius: 50%;
  /* line-height: 10px; */
  top: -18px;
  right: -8px;
}
.discount-container .discount-header{
  background: #dbfbff;
  padding: 10px;
  font-size: 20px;
  width: auto;
  font-weight: 600;
  color: #2f73b1;
}