.category-row {
  position:relative;
}
.cat-wrapper {
    width: 100%;
    position: relative;
}
.elementor-widget-container {
    position: relative;
}
.areas-item {
    margin: 5px;
    background-size: cover;
    background-position: center;
    height: 340px;
    border-radius: 2px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    box-shadow: 0 0 10px 0 rgb(51 51 51 / 15%);
}

.grid-item-link.hover-effect-flat {
    height: 100%;
    width: 100%;
    /* background-color: #00000042; */
    position: relative;
}
.areas-item-text {
    padding: 15px;
    position: absolute;
    bottom: 0;
}

.areas-item >* {
    color: #fff;
}

.grid-item-title {
    width: 100%;
    background: #000000a1;
    border-radius: 2px;
    display: block;
    box-sizing: border-box;
    text-align: center;
    padding: 8px 5px;
    font-size: 14px;
    color: #fff;
}
.slick-prev:before, .slick-next:before {
    color: #7a7a7a !important;
}
.areas-item-text a{
    text-decoration: none;
}