
.color-pick{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.color-pick .color-value{
  width: 20px;
  height: 20px;
  margin: 5px 2px;
}
.color-pick .color-value.active{
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.25); 
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.25);
}
.i360-value{
  background: 40% 40% no-repeat grey;
  display: flex;
  justify-content: center;
  color: #fff;
}
.i360-controls button{
  background: none;
  border: 1px solid #787878;
  padding: 10px;
  font-size: 20px;
  border-radius: 7px;
  margin: 0 5px;
  cursor: pointer;
  color: #545454;
}
/*# sourceMappingURL=ProductAttributes.css.map */