.prices-list, .price-row{
  list-style: none;
}
.prices-list{
  padding: 0;
  border-radius: 7px;
}
.price-row{
  padding: 5px 10px;
  transition: all .2s ease;
}
.price-row.active{
  background: #e2f6ff;
}
.price-row:not(:last-child){
  border-bottom: 1px solid #8b8b8b;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.price-row li{
  display: inline-block;
  margin: 10px;
  width: calc(50% - 20px);
  min-width: 150px;
}
.price-row ul{
  padding: 0;
}
.price-row ul li:last-child{
  text-align: right;
}
.header-prices{
  background: #8b8b8b;
  color: #fff;
  font-size: 17px;
}
.prices-wrapper{
  width: 100%;
}
.old-price-li{
  font-size: 12px;
  color: #828589;
  text-decoration: line-through;
  margin-right: 7px;
}