.slider {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 40%;
  background-color: #111;
  overflow: hidden;
}

.slider .slider__items .item {
  position: absolute;
  width: calc(100% - 50px - 50px);
  height: 100%;
  top: 0;
  left: unset;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  background-color: hsl(0deg 0% 28% / 60%);
  background-blend-mode: overlay;
}

.slider .slider__items .item .text {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.slider .slider__items .item .text a {
  max-width: 300px;
}

.slider .slider__items .item .image {
  width: 30%;
  height: 0;
  padding-bottom: 30%;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.slider .slider__btn-navigation {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  color: #EEE;
  cursor: pointer;
}

.slider .slider__btn-previous {
  left: 0%;
}

.slider .slider__btn-next {
  right: 0%;
}

@media screen and (max-width: 668px) {
  .slider .text {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin: 0 !important;
  }
  .slider .text .bigHeader {
    font-size: 14px !important;
  }
  .slider .text .btn {
    font-size: 11px !important;
  }
  .slider .image {
    margin-left: 32px;
  }
}
/*# sourceMappingURL=Slider.css.map */