.categories-page {
  width: 1140px;
  max-width: calc(100% - 1vw - 1vw);
  margin: 0 auto;
  padding: 1vw;
}

.categories-page .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.categories-page .list > * {
  width: calc(25% - 1vw);
  margin: 0.5vw 0.5vw;
}

@media screen and (max-width: 991px) {
  .categories-page .list > * {
    width: calc(50% - 2vw - 2vw);
    margin: 2vw;
  }
}

@media screen and (max-width: 575px) {
  .categories-page .list > * {
    width: calc(100% - 3vw - 3vw);
    margin: 3vw;
  }
}
/*# sourceMappingURL=CategoriesPage.css.map */