.services-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.services-wrapper .services-item {
    width: 33.33333%;
    padding-top: 40px;
    padding-bottom: 40px;
    transition: all .3s;
    display: flex;
    position: relative;
    overflow: hidden;
    color: #333;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.services-wrapper .services-item .icon-bg {
    visibility: hidden;
    opacity: 0;
    color: #0053a0;
    position: absolute;
    top: -18px;
    left: -49px;
    font-size: 90px;
}
.services-wrapper .services-item:hover .icon-bg {
    display: unset;
    color: #0053a0;
    opacity: 0.1;
    visibility: visible;
}
.services-wrapper .services-item .services-more {
    /* visibility: hidden; */
    transition: all .3s;
    font-size: 12px;
    color: #363636;
}
/* .services-wrapper .services-item:hover .services-more {
    visibility: visible;
} */

.services-wrapper .services-item .main-icon {
    font-size:40px;
    color:#0053a0;
}
.services-wrapper .services-item h4:after {
    content: '';
    border-bottom: 2px solid #93c7f7;
    position: absolute;
    display: block;
    width: 30px;
    margin: auto;
    margin-top: 15px;
    right: 0;
    left: 0;
}
.services-wrapper .services-item:hover {
    box-shadow: 0 0 30px 1px rgb(89 106 128 / 20%);
}

@media screen and (max-width:768px){
    .services-item {
        width:50% !important;
    }
    
}
@media screen and (max-width:425px){
    .services-item {
        width:100% !important;
    }
    
}